import cn from "classnames";

import "swiper/css";
import "swiper/css/grid";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Grid, Mousewheel, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { Stack } from "react-bootstrap";
import classes from "./styles.module.scss";

export default function ({
  className = "",
  elementKey = "carousel",
  navigation = true,
  loop = true,
  pagination = false,
  autoplay = false,
  autoHeight = false,
  grid = false,
  slidesPerView = 1,
  centeredSlides = false,
  breakpoints = {
    480: { slidesPerView },
    768: { slidesPerView },
    992: { slidesPerView },
    1200: { slidesPerView },
    1400: { slidesPerView },
  },
  spaceBetween = 20,
  items,
  onSlideChange,
}) {
  return (
    <div className={cn(className, classes.Carousel, elementKey)}>
      <Swiper
        modules={[Navigation, Pagination, Autoplay, Mousewheel, Grid]}
        navigation={{
          enabled: navigation,
          nextEl: `.${elementKey} .${classes.Carousel__nextEl}`,
          prevEl: `.${elementKey} .${classes.Carousel__prevEl}`,
        }}
        autoHeight={autoHeight}
        autoplay={autoplay}
        grid={grid}
        loop={loop}
        centeredSlides={centeredSlides}
        pagination={{ enabled: pagination, dynamicBullets: true, clickable: true }}
        spaceBetween={spaceBetween}
        slidesPerView={slidesPerView}
        breakpoints={breakpoints}
        onSlideChange={onSlideChange}
      >
        {items.map((i, idx) => (
          <SwiperSlide key={idx}>{i}</SwiperSlide>
        ))}
      </Swiper>
      {navigation && (
        <Stack className="navigation gap-2 mx-3">
          <span className={classes.Carousel__prevEl}>
            <img src="/assets/prev.svg" width="8" height="12" alt="Prev" />
          </span>
          <span className={classes.Carousel__nextEl}>
            <img src="/assets/next.svg" width="8" height="12" alt="Next" />
          </span>
        </Stack>
      )}
    </div>
  );
}
